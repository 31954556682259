import React, { useEffect, useRef } from "react";
import "./DrawerStyles.scss";
import { RouteProps, Routes } from "../../routes/Routes";
import OutsideClick from "../OutsideClick/OutsideClick";


interface DrawerProps {
    expand: boolean
    onClose: () => void;
}

const Drawer = (props: DrawerProps) => {
    const { expand, onClose } = props;

    const wrapperRef = useRef(null);

    OutsideClick(wrapperRef, () => {
        if (expand) onClose();
    })

    useEffect(() => {
        if(!expand) {
            setTimeout(() => {
                document.getElementById("drawer")?.classList.add("d-none");
            }, 125)
        } else {
            document.getElementById("drawer")?.classList.remove("d-none");
        }
    }, [expand]);

    return (
        <div className="drawer-wrapper" aria-expanded={expand}>
            <div id="drawer" className={`drawer`} ref={wrapperRef}>
                <button type="button" className="close-btn" onClick={() => onClose()}>
                    Lukk
                </button>
                <ul>
                    {Routes.map((route: RouteProps) => (<li><a href={route.url} onClick={() => onClose()}>{route.label}</a></li>))}
                </ul>
            </div>
        </div>
    );
}

export default Drawer;