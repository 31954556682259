
import React from 'react';
import "./styles/styles.scss";
import RootContainer from "./Root/Root";
<script src="https://kit.fontawesome.com/705cc214ec.js" crossOrigin="anonymous"></script>

const App = () => {

  return (
      <RootContainer />
  );
}

export default App;

