import React, { useEffect } from "react";
import FadeInSection from "../components/Section/FadeInSection";
import {Maalgrupper, Undervisning, Advarsel, Kursavgift, OmOss} from "../routes/Routes"
<script src="https://kit.fontawesome.com/705cc214ec.js" crossOrigin="anonymous"></script>

const textCarousel: string[] = ["helsesevesenet", "skole- og utdanningssektoren"];

const speed = 80;
const deleteSpeed = 50;


function deleteChar (carouselIndex: number){
    const text = document.getElementById("typewriter")!.innerHTML;
    if (text.length > 0) {
        document.getElementById("typewriter")!.innerHTML = text.substring(0, text.length - 1);
        setTimeout(() => deleteChar(carouselIndex), deleteSpeed);
    }
    else {
        setTimeout(() => {
            typeChar(0, (carouselIndex + 1) % textCarousel.length );
        }, 500);
    }
};

const typeChar = (i: number, carouselIndex: number) => {
    const text = textCarousel[carouselIndex];
    if (i < text.length) {
        document.getElementById("typewriter")!.innerHTML += text.charAt(i);
        i++;
        setTimeout(() => typeChar(i, carouselIndex), speed);
    }
    else {
        setTimeout(() => {
            deleteChar(carouselIndex);
        }, 4000);
    }
};

const HomePage = () => {

    useEffect(() => {
        typeChar(0, 0);
    }, []);

    return (
        <div>
            <div className="page-img">
                <h1 className="logo-text">Tillitskoden
                    <span>Metode for tillit og samarbeid for<span id="typewriter"></span></span>
                </h1>
            </div>
            <div className="flex-center page-content">
                <div className="center">
                <FadeInSection>
                    <section id={Undervisning.substring(2, Undervisning.length)}>
                        <div className="row w-100 align-center">
                            <div className="column w-100">
                                <h2>Undervisning i metode for tillit og samarbeid</h2>
                                <p>Forskning viser at tillit og samarbeid er viktige faktorer for å oppnå felles mål. Arbeidsplasser med høy grad av tillit gjør det bedre økonomisk, har lavere turnover av ansatte og bedre kunde- og pasienttilfredshet (<i>Fulmer, I., Gerhart, B, & Scott, K. 2012</i>).

                                    De fleste er enige i at tillit og samarbeid er bra. Men hva er egentlig tillit, og hvordan motiverer vi til samarbeid? Tre modeller kan lære oss noe om dette:
                                </p>
                                <ul>
                                   <li>Amy Cuddy’s Stereotype Content Model (SCM) fra forskning på førsteinntrykk og nonverbal kommunikasjon.</li>

                                   <li>David Rock's SCARF-modell for inspirasjon, motivasjon og samarbeid med utgangspunkt i hvordan hjernen fungerer.</li>

                                   <li>Robin Dreeke´s «The Code of Trust» med utgangspunkt i kunnskap og erfaringer fra FBI Behavioral Analysis Program.</li>
                                </ul>
                                <p>
                                    I undervisningen blir deltakeren kjent med modellene og hvordan de kan bringes sammen i en dynamisk og kunnskapsbasert modell/metode for etablering av tillit og samarbeid gjennom imøtekommelse av grunnleggende sosiale behov.
                                </p>
                            </div>
                            <div className="icon-bubble left">
                                <i className='fas fa-info' />
                            </div>
                        </div>
                    </section>
                    </FadeInSection>
                    <FadeInSection>
                    <section >
                        <div className="row w-100 align-center">
                            <div className="column w-100">
                                <h2>Undervisningsplan (ca. 2 timer + pauser)</h2>
                                <ul>
                                    <li>Hva er tillit?</li>
                                    <li>Hvordan motivere til samarbeid?</li>
                                    <li>Holdninger og adferd</li>
                                    <li>Enkleste veien til tillit</li>
                                    <li>Modell for tillit og samarbeid</li>
                                    <li>Største hinder for suksess</li>

                                </ul>
                            </div>
                            <div className="icon-bubble right">
                                <i className='fas fa-chalkboard-teacher' />
                            </div>
                        </div>
                    </section>
                    </FadeInSection>
                    <FadeInSection>
                    <section id={Maalgrupper.substring(2, Maalgrupper.length)}>
                        <div className="row w-100 align-center">
                            <div className="column w-100">
                                <h2>Målgruppe</h2>
                                <p>Målgrupper for undervisningen er arbeidsgivere, ledere og ansatte som er avhengige av tillit og samarbeid mot felles mål innen helse- og omsorgssektoren, undervisningssektoren samt øvrige organisasjoner og foretak i privat og offentlig sektor.</p>
                            </div>
                            <div className="icon-bubble left">
                                <i className='fas fa-users' />
                            </div>
                        </div>
                    </section>
                    </FadeInSection>
                    <FadeInSection>
                    <section id={Advarsel.substring(2, Advarsel.length)}>
                        <div className="row w-100 align-center">
                            <div className="column w-100"> 
                                <h2>Advarsel</h2>
                                <p>Undervisningen inneholder generelle beskrivelser av holdninger og adferd som kan oppleves som utleverende og personlig for enkelte deltakere. Undervisningen passer derfor best for personer med vilje og evne til å «ta et steg til siden», - og uten fordømmelse, reflektere over egne holdninger og adferd i møte med andre mennesker.</p>
                            </div>
                            <div className="icon-bubble right">
                                <i className='fas fa-exclamation' />
                            </div>
                        </div>
                    </section>
                    </FadeInSection>
                    <FadeInSection>
                    <section id={Kursavgift.substring(2, Kursavgift.length)}>
                        <div className="row w-100 align-center">
                            <div className="column w-100"> 
                                <h2>Kursavgift</h2>
                                <p>NOK 12 000,- for inntil 12 personer. Ytterligere deltakere faktureres NOK 1 000,- pr. person. Eventuelle utgifter til leie av kurslokale samt reise og opphold for kursholder kommer i tillegg. <br/>
                                    Faktura/refusjon
                                    Kursavgift innbetales på faktura eller Vipps før kursstart. Kursavgift for deltakere som definerer seg i målgruppen, men ikke har utbytte av undervisningen refunderes med kr. 1000 pr. person.
                                </p>
                                </div>
                            <div className="icon-bubble left">
                                <i className='fas fa-comment-dollar' ></i>
                            </div>
                        </div>
                    </section>
                    </FadeInSection>
                    <FadeInSection>
                    <section id={OmOss.substring(2, OmOss.length)}>
                        <div className="row w-100 align-center">
                            <div className="column w-100"> 
                                <h2>Om Oss</h2>
                                <p>Tillitskoden er et undervisningsforetak med formål å dele kunnskap om hvordan tillit og samarbeid etableres gjennom imøtekommelse av grunnleggende sosiale behov.
                                Undervisningsopplegget er utarbeidet av Ellen Ånestad Moen og Ronny Kolstad som begge jobber med Individual Placement and support (IPS) i Klinikk for psykisk helse ved Sørlandet sykehus i Kristiansand.
                                </p>
                            </div>
                            <div className="icon-bubble right">
                                <i className='fas fa-user-friends' />
                            </div>
                        </div>
                    </section>
                    </FadeInSection>
                </div>
            </div>
        </div>
    );
}

export default HomePage;