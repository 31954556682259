import { useEffect } from "react";

const OutsideClick = (ref: any, callback: any) => {
    const handleClick = (e: { target: any; }) => {
        if(ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    });

    const handleKeyDown = (event: KeyboardEvent) => {
        if(ref.current && event.key === "Escape") {
            callback();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    });

}

export default OutsideClick;