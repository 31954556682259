import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import TopMenu from '../layouts/TopMenu';
import Footer from '../layouts/Footer';
import HomePage from '../pages/HomePage';
<script src="https://kit.fontawesome.com/705cc214ec.js" crossOrigin="anonymous"></script>

const Root = () => {

  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <TopMenu />
        </header>
        <main>
          <div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="" element={<HomePage />} />
            </Routes>
        </div>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default Root;
