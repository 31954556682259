import React from "react";
import "./fadeInSectionStyles.scss";

interface FadeInSectionProps {
    children: any;
};

const FadeInSection = (props: FadeInSectionProps) => {
    const {children} = props;

    const [isVisible, setVisible] = React.useState(false);

    const domRef = React.useRef(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setVisible(entry.isIntersecting);
                }
            });
        });
        const {current} = domRef;
        if(current) {
        observer.observe(current);
        return () => observer.unobserve(current);
        }
    }, []);

    return (
      <div
        className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {children}
      </div>
    );
  }

  export default FadeInSection;