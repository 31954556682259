import React from "react";
import "./footerStyles.scss"

const contactcard1 = ["Org.nr. 922 942 196", "Tillitskoden Ronny Kolstad"];
const contactcard2 = ["Tlf: 905 31 556 ", "E-mail: post@tillitskoden.org"];
const contactcard3 = ["Adresse: Odderhei Terrasse 159,\n 4639 Kristiansand S"];

const Footer = () => {
    return (
        <footer id="kontakt">
             <div className="flex-center page-content">
                <div className="center">
                    <h2>Kontakt</h2>
                    <div className="content-card-wrapper">
                        <div className="contact-card">
                            <i className='far fa-id-badge' />
                            <div className="column">
                                {contactcard1.map((c) => (<span>{c}</span>))}
                            </div>
                        </div>
                        <div className="contact-card">
                            <i className='fas fa-phone' />
                            <div className="column">
                                {contactcard2.map((c) => (<span>{c}</span>))}
                            </div>
                        </div>
                        <div className="contact-card">
                            <i className='fas fa-map-marker-alt' />
                            <div className="column">
                                {contactcard3.map((c) => (<span>{c}</span>))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-link-wrapper">
                    <a href="mailto:post@tillitskoden.org?Subject=Undervisning" target="_top" title="Kontakt via e-mail" rel="noopener noreferrer"><i className="fas fa-envelope-square" aria-hidden="true"></i></a>
                    <a href="https://www.linkedin.com/company/tillitskoden?" target="_blank" title="Linkedin" rel="noopener noreferrer"><i className="fab fa-linkedin" aria-hidden="true"></i></a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;