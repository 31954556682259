
// HomePageSections ----------------
export const Undervisning = "/#undervisning";
export const Maalgrupper = "/#maalgruppe";
export const Advarsel = "/#advarsel";
export const Kursavgift = "/#kursavgift";
export const OmOss = "/#omoss";
// ---------------------------------
export const ContactRoute = "/#kontakt";

export interface RouteProps {
    url: string;
    label: string;
}

export const Routes: RouteProps[] = [
    { 
        url: Undervisning,
        label: "Undervisning",
    },
    { 
        url: Maalgrupper,
        label: "Målgrupper",
    },
    { 
        url: Kursavgift,
        label: "Kursavgift",
    },
    { 
        url: OmOss,
        label: "Om Oss",
    },
    { 
        url: ContactRoute,
        label: "Kontakt",
    }
];

export default Routes;
