import React from "react";
import "./TopMenuStyles.scss";
import Drawer from "../components/Drawer/Drawer";
import { RouteProps, Routes } from "../routes/Routes";
import TillitskodenVektorisert from "../images/TillitskodenVektorisert.png";

const TopMenu = () => {

    const [expandDrawer, setExpandDrawer] = React.useState<boolean>(false);

    return (
        <div className="top-menu">
            <div className="sm-wrapper">
                <button type="button" className="logo-btn" onClick={() => setExpandDrawer(!expandDrawer)} >
                    &#9776;
                </button>
                <span>TILLITSKODEN</span>
                <div className="counter-weight"/>
            </div>
            <a href="#" className="logo" title="Hjem">
                <img src={TillitskodenVektorisert} alt="TK" />
            </a>
            <div className="links">{Routes.map((route: RouteProps) => (<a href={route.url} >{route.label}</a>))}</div>
            <Drawer expand={expandDrawer} onClose={() => setExpandDrawer(false)}/>
        </div>
    );
}

export default TopMenu;